import React from "react"

import BarStep from "./partials/bar-step"
import Preferences from "../preference/preferences";
import Layout from "../../components/layout";

const PreferencesPage = () => {
    const checked = ["step1", "step2"];
    return(
      <div>
        <Layout>
            <BarStep selected="step3" checked={checked} />
            <Preferences navigateTo='/profile/congratulations'/>
        </Layout>
      </div>
    )
}

export default PreferencesPage
